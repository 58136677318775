import React, { useEffect, useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';

import { toast } from 'react-toastify';
import Search from '~/components/Search';

import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import Separator from '~/components/Separator';
import api from '~/services/api';

import {
  FormInformacaoNutricional,
  formInformacaoNutricionalBlank,
  porcoes,
  partesDecimais,
  medidasCaseiras,
} from './protocols';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import { Col, Row } from 'react-bootstrap';
import {
  InputPeso,
  InputSelect,
  InputPercent,
  InputText2 as InputText,
} from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import defaultData from './validations/defaultData.json';
import { schema } from './validations/schema';

const InformacaoNutricional: React.FC = () => {
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [iniInicializado, setInitInicializado] = useState(false);
  const [showSearch, setShowSearch] = useState(true);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const [formInformacaoNutricional, setFormInformacaoNutricional] =
    useState<FormInformacaoNutricional>(formInformacaoNutricionalBlank);

  useEffect(() => {
    if (iniInicializado) setInitInicializado(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInformacaoNutricional]);

  useEffect(() => {
    const valorCalorico = formInformacaoNutricional.valor_calorico.value;

    if (valorCalorico === '') {
      return setFormInformacaoNutricional((prevState) => {
        return {
          ...prevState,
          valor_kj: {
            ...prevState.valor_kj,
            value: formatCurrencyAsText(0),
          },
        };
      });
      return;
    }

    if (typeof valorCalorico === 'number') {
      const valorKj = valorCalorico * 4.19;
      return setFormInformacaoNutricional((prevState) => {
        return {
          ...prevState,
          valor_kj: {
            ...prevState.valor_kj,
            value: formatCurrencyAsText(valorKj),
          },
        };
      });
    }

    const valorKj = transformAsCurrency(valorCalorico) * 4.19;
    setFormInformacaoNutricional((prevState) => {
      return {
        ...prevState,
        valor_kj: {
          ...prevState.valor_kj,
          value: formatCurrencyAsText(valorKj),
        },
      };
    });
  }, [formInformacaoNutricional.valor_calorico.value]);

  const resetFormData = () => {
    reset(defaultData);
  };

  const onRowClick = (param: GridRowParams) => {
    const { row } = param;

    const {
      valor_calorico,
      cod_info_nutricional,
      des_info_nutricional,
      porcao,
      des_porcao,
      vd_valor_calorico,
      carboidrato,
      vd_carboidrato,
      proteina,
      vd_proteina,
      gordura_total,
      vd_gordura_total,
      gordura_saturada,
      vd_gordura_saturada,
      gordura_trans,
      vd_gordura_trans,
      colesterol,
      vd_colesterol,
      fibra_alimentar,
      vd_fibra_alimentar,
      calcio,
      vd_calcio,
      ferro,
      vd_ferro,
      sodio,
      vd_sodio,
      parte_inteira,
      parte_decimal,
      unidade_porcao,
      medida_utilizada,
    } = row;

    setValue('valor_calorico', formatCurrencyAsText(valor_calorico));
    setValue('cod_info_nutricional', cod_info_nutricional);
    setValue('des_info_nutricional', des_info_nutricional);
    setValue('porcao', formatCurrencyAsText(porcao));
    setValue('des_porcao', formatCurrencyAsText(des_porcao));
    setValue('vd_valor_calorico', formatCurrencyAsText(vd_valor_calorico));
    setValue('carboidrato', formatCurrencyAsText(carboidrato));
    setValue('vd_carboidrato', formatCurrencyAsText(vd_carboidrato));
    setValue('proteina', formatCurrencyAsText(proteina));
    setValue('vd_proteina', formatCurrencyAsText(vd_proteina));
    setValue('gordura_total', formatCurrencyAsText(gordura_total));
    setValue('vd_gordura_total', formatCurrencyAsText(vd_gordura_total));
    setValue('gordura_saturada', formatCurrencyAsText(gordura_saturada));
    setValue('vd_gordura_saturada', formatCurrencyAsText(vd_gordura_saturada));
    setValue('gordura_trans', formatCurrencyAsText(gordura_trans));
    setValue('vd_gordura_trans', formatCurrencyAsText(vd_gordura_trans));
    setValue('colesterol', formatCurrencyAsText(colesterol));
    setValue('vd_colesterol', formatCurrencyAsText(vd_colesterol));
    setValue('fibra_alimentar', formatCurrencyAsText(fibra_alimentar));
    setValue('vd_fibra_alimentar', formatCurrencyAsText(vd_fibra_alimentar));
    setValue('calcio', formatCurrencyAsText(calcio));
    setValue('vd_calcio', formatCurrencyAsText(vd_calcio));
    setValue('ferro', formatCurrencyAsText(ferro));
    setValue('vd_ferro', formatCurrencyAsText(vd_ferro));
    setValue('sodio', formatCurrencyAsText(sodio));
    setValue('vd_sodio', formatCurrencyAsText(vd_sodio));
    setValue('parte_inteira', formatCurrencyAsText(parte_inteira));
    setValue(
      'parte_decimal',
      partesDecimais.find((item) => item.value === parte_decimal),
    );
    setValue(
      'unidade_porcao',
      porcoes.find((item) => item.value === unidade_porcao),
    );
    setValue(
      'medida_utilizada',
      medidasCaseiras.find((item) => item.value === medida_utilizada),
    );

    setUpdate(true);
    setShowSearch(false);
  };

  const onCancel = () => {
    resetFormData();
    setShowSearch(true);
  };

  const onDelete = async () => {
    const cod_info_nutricional = getValues('cod_info_nutricional');
    const res = await api.delete(
      `/informacaoNutricional/${cod_info_nutricional}`,
    );

    const { message } = res.data;

    resetFormData();
    setUpdate(false);
    setShowSearch(true);
    return toast.success(message);
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
    setShowSearch(false);
  };

  const onSave = handleSubmit(async (data) => {
    if (
      data.des_info_nutricional.trim() === 'NENHUM' ||
      data.des_info_nutricional.trim() === 'NENHUMA' ||
      data.des_info_nutricional.trim() === 'NENHUM(A)'
    ) {
      return toast.warn(
        'Descrição contém palavra não permitida "Nenhum/Nenhuma".',
      );
    }
    const upsert = {
      des_info_nutricional: data.des_info_nutricional.trim(),
      des_porcao: data.des_porcao.trim(),
      porcao: transformAsCurrency(data.porcao),
      valor_calorico: transformAsCurrency(data.valor_calorico),
      carboidrato: transformAsCurrency(data.carboidrato),
      proteina: transformAsCurrency(data.proteina),
      gordura_total: transformAsCurrency(data.gordura_total),
      gordura_saturada: transformAsCurrency(data.gordura_saturada),
      gordura_trans: transformAsCurrency(data.gordura_trans),
      colesterol: transformAsCurrency(data.colesterol),
      fibra_alimentar: transformAsCurrency(data.fibra_alimentar),
      calcio: transformAsCurrency(data.calcio),
      ferro: transformAsCurrency(data.ferro),
      sodio: transformAsCurrency(data.sodio),
      parte_inteira: transformAsCurrency(data.parte_inteira),
      parte_decimal: data.parte_decimal.value,
      unidade_porcao: data.unidade_porcao.value,
      medida_utilizada: data.medida_utilizada.value,
      vd_carboidrato: transformAsCurrency(data.vd_carboidrato),
      vd_valor_calorico: transformAsCurrency(data.vd_valor_calorico),
      vd_proteina: transformAsCurrency(data.vd_proteina),
      vd_gordura_total: transformAsCurrency(data.vd_gordura_total),
      vd_gordura_saturada: transformAsCurrency(data.vd_gordura_saturada),
      vd_gordura_trans: transformAsCurrency(data.vd_gordura_trans),
      vd_colesterol: transformAsCurrency(data.vd_colesterol),
      vd_fibra_alimentar: transformAsCurrency(data.vd_fibra_alimentar),
      vd_calcio: transformAsCurrency(data.vd_calcio),
      vd_ferro: transformAsCurrency(data.vd_ferro),
      vd_sodio: transformAsCurrency(data.vd_sodio),
    };

    if (isUpdate) {
      const res = await api.put(
        `/informacaoNutricional/${data.cod_info_nutricional}`,
        upsert,
      );

      const { message } = res.data;

      resetFormData();
      setShowSearch(true);
      return toast.success(message);
    }

    const res = await api.post(`/informacaoNutricional/`, upsert);
    const { message } = res.data;
    setTimeout(() => {
      resetFormData();
      setShowSearch(false);
    }, 200);
    return toast.success(message);
  });

  return (
    <Container>
      {showSearch && (
        <Search newData={onNew} onRowClick={onRowClick} codTela={35} />
      )}

      {!showSearch && (
        <FormDefault
          codTela={35}
          title="Cadastro de Informação Nutricional"
          codigoRegistro={[
            {
              value: formInformacaoNutricional.cod_info_nutricional.value,
              des_campo: 'Código',
            },
          ]}
          onSave={async () => {
            await onSave();
          }}
          onCancel={onCancel}
          isUpdate={isUpdate}
          onNew={onNew}
          onDelete={onDelete}
          onClearFields={resetFormData}
          onReturnSearch={() => {
            setShowSearch(true);
            resetFormData();
          }}
        >
          <Row>
            <Col sm={12} md={12}>
              <InputText
                label="Descrição"
                maxLength={50}
                placeholder="Informe a Descrição"
                name="des_info_nutricional"
                register={register}
                control={control}
                isError={!!errors.des_info_nutricional}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <Separator labelText="Informações Gerais" />
            </Col>
            <Col sm={12} md={2}>
              <InputPeso
                label="Tipo de Porção"
                placeholder="0,00"
                decimals={2}
                name="porcao"
                register={register}
                control={control}
                isError={!!errors.porcao}
              />
            </Col>
            <Col sm={12} md={2}>
              <InputSelect
                label="UN"
                placeholder={
                  getValues('unidade_porcao')?.label || `- Selecione a UN -`
                }
                name="unidade_porcao"
                register={register}
                isError={!!errors.unidade_porcao}
                control={control}
                options={porcoes}
                changeSelected={(selected) =>
                  setValue('unidade_porcao', selected)
                }
              />
            </Col>
            <Col sm={12} md={8}>
              <InputText
                label="Descrição da Porção"
                maxLength={25}
                placeholder="Informe a Descrição da Porção"
                name="des_porcao"
                register={register}
                control={control}
                isError={!!errors.des_porcao}
              />
            </Col>
          </Row>

          {/* Valor energético */}

          <Separator labelText="Valor Energético" />
          <Row className="d-flex justify-content-center">
            <Col sm={12} md={2}>
              <InputPeso
                label="KCal"
                placeholder="0,00"
                decimals={2}
                name="valor_calorico"
                register={register}
                control={control}
                isError={!!errors.valor_calorico}
              />
            </Col>
            <Col sm={12} md={2}>
              <InputPeso
                label="KJ"
                placeholder="0,00"
                decimals={2}
                name="valor_kj"
                register={register}
                control={control}
                isError={!!errors.valor_kj}
              />
            </Col>
            <Col sm={12} md={2}>
              <InputPercent
                label="VD"
                placeholder="0,00"
                min={0}
                max={100}
                step="2"
                name="vd_valor_calorico"
                register={register}
                control={control}
                isError={!!errors.vd_valor_calorico}
              />
            </Col>
          </Row>

          <Row className="d-flex justify-content-center">
            <Col sm={12} md={6}>
              <Row>
                <Col sm={12} md={6}>
                  <InputPeso
                    label="Carboidrato"
                    placeholder="0,00"
                    decimals={2}
                    min={0.01}
                    max={99999}
                    name="carboidrato"
                    register={register}
                    control={control}
                    isError={!!errors.carboidrato}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <InputPercent
                    label="VD"
                    placeholder="0,00"
                    min={0}
                    max={100}
                    step="2"
                    name="vd_carboidrato"
                    register={register}
                    control={control}
                    isError={!!errors.vd_carboidrato}
                  />
                </Col>

                <Col sm={12} md={6}>
                  <InputPeso
                    label="Proteína"
                    placeholder="0,00"
                    decimals={2}
                    min={0.01}
                    max={99999}
                    name="proteina"
                    register={register}
                    control={control}
                    isError={!!errors.proteina}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <InputPercent
                    label="VD"
                    placeholder="0,00"
                    min={0}
                    max={100}
                    step="2"
                    name="vd_proteina"
                    register={register}
                    control={control}
                    isError={!!errors.vd_proteina}
                  />
                </Col>

                <Col sm={12} md={6}>
                  <InputPeso
                    label="Gorduras Totais"
                    placeholder="0,00"
                    decimals={2}
                    min={0.01}
                    max={99999}
                    name="gordura_total"
                    register={register}
                    control={control}
                    isError={!!errors.gordura_total}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <InputPercent
                    label="VD"
                    placeholder="0,00"
                    min={0}
                    max={100}
                    step="2"
                    name="vd_gordura_total"
                    register={register}
                    control={control}
                    isError={!!errors.vd_gordura_total}
                  />
                </Col>

                <Col sm={12} md={6}>
                  <InputPeso
                    label="Gorduras Saturadas"
                    placeholder="0,00"
                    decimals={2}
                    min={0.01}
                    max={99999}
                    name="gordura_saturada"
                    register={register}
                    control={control}
                    isError={!!errors.gordura_saturada}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <InputPercent
                    label="VD"
                    placeholder="0,00"
                    min={0}
                    max={100}
                    step="2"
                    name="vd_gordura_saturada"
                    register={register}
                    control={control}
                    isError={!!errors.vd_gordura_saturada}
                  />
                </Col>

                <Col sm={12} md={6}>
                  <InputPeso
                    label="Gorduras Trans"
                    placeholder="0,00"
                    decimals={2}
                    min={0.01}
                    max={99999}
                    name="gordura_trans"
                    register={register}
                    control={control}
                    isError={!!errors.gordura_trans}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <InputPercent
                    label="VD"
                    placeholder="0,00"
                    min={0}
                    max={100}
                    step="2"
                    name="vd_gordura_trans"
                    register={register}
                    control={control}
                    isError={!!errors.vd_gordura_trans}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={6}>
              <Row>
                <Col sm={12} md={6}>
                  <InputPeso
                    label="Colesterol"
                    placeholder="0,00"
                    decimals={2}
                    min={0.01}
                    max={99999}
                    name="colesterol"
                    register={register}
                    control={control}
                    isError={!!errors.colesterol}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <InputPercent
                    label="VD"
                    placeholder="0,00"
                    min={0}
                    max={100}
                    step="2"
                    name="vd_colesterol"
                    register={register}
                    control={control}
                    isError={!!errors.vd_colesterol}
                  />
                </Col>

                <Col sm={12} md={6}>
                  <InputPeso
                    label="Fibra Alimentar"
                    placeholder="0,00"
                    decimals={2}
                    min={0.01}
                    max={99999}
                    name="fibra_alimentar"
                    register={register}
                    control={control}
                    isError={!!errors.fibra_alimentar}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <InputPercent
                    label="VD"
                    placeholder="0,00"
                    min={0}
                    max={100}
                    step="2"
                    name="vd_fibra_alimentar"
                    register={register}
                    control={control}
                    isError={!!errors.vd_fibra_alimentar}
                  />
                </Col>

                <Col sm={12} md={6}>
                  <InputPeso
                    label="Cálcio"
                    placeholder="0,00"
                    decimals={2}
                    min={0.01}
                    max={99999}
                    name="calcio"
                    register={register}
                    control={control}
                    isError={!!errors.calcio}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <InputPercent
                    label="VD"
                    placeholder="0,00"
                    min={0}
                    max={100}
                    step="2"
                    name="vd_calcio"
                    register={register}
                    control={control}
                    isError={!!errors.vd_calcio}
                  />
                </Col>

                <Col sm={12} md={6}>
                  <InputPeso
                    label="Ferro"
                    placeholder="0,00"
                    decimals={2}
                    min={0.01}
                    max={99999}
                    name="ferro"
                    register={register}
                    control={control}
                    isError={!!errors.ferro}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <InputPercent
                    label="VD"
                    placeholder="0,00"
                    min={0}
                    max={100}
                    step="2"
                    name="vd_ferro"
                    register={register}
                    control={control}
                    isError={!!errors.vd_ferro}
                  />
                </Col>

                <Col sm={12} md={6}>
                  <InputPeso
                    label="Sódio"
                    placeholder="0,00"
                    decimals={2}
                    min={0.01}
                    max={99999}
                    name="sodio"
                    register={register}
                    control={control}
                    isError={!!errors.sodio}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <InputPercent
                    label="VD"
                    placeholder="0,00"
                    min={0}
                    max={100}
                    step="2"
                    name="vd_sodio"
                    register={register}
                    control={control}
                    isError={!!errors.vd_sodio}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          {/* Informações especiais */}

          <Row>
            <Col sm={12} md={12}>
              <Separator labelText="Informações Especiais" />
            </Col>
            <Col sm={12} md={2}>
              <InputPeso
                label="Parte Inteira"
                placeholder="0,00"
                decimals={2}
                min={0.01}
                max={99999}
                name="parte_inteira"
                register={register}
                control={control}
                isError={!!errors.parte_inteira}
              />
            </Col>
            <Col sm={12} md={3}>
              <InputSelect
                label="Parte Decimal"
                placeholder=""
                name="parte_decimal"
                register={register}
                isError={!!errors.parte_decimal}
                control={control}
                options={partesDecimais}
                changeSelected={(selected) =>
                  setValue('parte_decimal', selected)
                }
              />
            </Col>
            <Col sm={12} md={4}>
              <InputSelect
                label="Medida Caseira Utilizada"
                placeholder=""
                name="medida_utilizada"
                register={register}
                isError={!!errors.medida_utilizada}
                control={control}
                options={medidasCaseiras}
                changeSelected={(selected) =>
                  setValue('medida_utilizada', selected)
                }
              />
            </Col>
          </Row>
        </FormDefault>
      )}
    </Container>
  );
};

export default InformacaoNutricional;
